import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'

Vue.use(vueCustomElement)

Vue.customElement('znajdz-gabinet-widget', App, {shadow: true, shadowCss: `
.zg-default-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 15px;
  font-family: "Lato";
  cursor: pointer;
  border-color: transparent;
  color: white;
}
.zg-default-button:focus {
  outline: none;
}
.zg-default-button:hover {
  opacity: 0.9;
}
.zg-default-button.primary {
  background-color: #1baad6;
}`});