<template>
  <div id="widget">
    <button class="zg-default-button primary" @click="openAppointmentTab">Umów się</button>
  </div>
</template>

<script>
export default {
  props: {
    token: {},
    clinic: {
      type: Boolean,
      default: false
    }
  },
  name: "widget",
  data() {
    return {};
  },
  computed: {
    link() {
      return this.clinic
        ? `${process.env.VUE_APP_URL_FRONTEND}/p/${this.token}/`
        : `${process.env.VUE_APP_URL_FRONTEND}/w/${this.token}/`;
    }
  },
  methods: {
    openAppointmentTab() {
      window.open(this.link, "_blank");
    }
  }
};
</script>

<style lang="css">
.zg-default-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 15px;
  font-family: "Lato";
  cursor: pointer;
  border-color: transparent;
  color: white;
}
.zg-default-button:focus {
  outline: none;
}
.zg-default-button:hover {
  opacity: 0.9;
}
.zg-default-button.primary {
  background-color: #1baad6;
}
</style>